<template>
  <v-card class="prd-bulk-panel" color="primary" flat tile dark>
    <v-card-text>
      <v-row class="align-center">
        <v-col class="subtitle-2"> {{ itemsSelected.length }} Elementos seleccionados </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <slot name="bulkActions" :itemsSelected="itemsSelected"></slot>
        </v-col>
        <v-col cols="auto">
          <v-btn
            dark
            outlined
            depressed
            small
            @click="deleteItems"
            :disabled="cantDeleteSelectedItems"
          >
            <v-icon class="mr-2">delete</v-icon>Eliminar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    itemsSelected: { type: Array, default: () => [] },
    crudActions: { type: Object, required: true },
  },
  methods: {
    deleteItems() {
      const itemsId = this.itemsSelected.map(i => i.id);
      this.$emit("deleteItems", itemsId);
    },
  },
  computed: {
    cantDeleteSelectedItems() {
      return !!this.itemsSelected.find(i => !i.actions.canDelete);
    },
  },
};
</script>

<style scoped>
.prd-bulk-panel {
  min-height: 60px;
}
</style>
